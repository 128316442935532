import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import { api } from "../../api/api";
import SecretSantaPairsInfoSendApproveDialog from "../dialog/SecretSantaPairsInfoSendApproveDialog";
import SecretSantaPresentCollectionApproveDialog from "../dialog/SecretSantaPresentCollectionApproveDialog";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

}));

export default function SecretSantaAdmin(props) {
  const classes = useStyles();
  const [pairsInfoDialog, openPairsInfoDialog] = useState(false);
  const [pairsInfoApprove, setPairsInfoApprove] = useState(false);
  const [presentCollectionDialog, openPresentCollectionDialog] = useState(false);
  const [presentCollectionApprove, setPresentCollectionApprove] = useState(false);
  const [santaState, setSantaState] = useState(0);
  const [requestSantaState, setRequestSantaState] = useState(true);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const[lockersInfo, setLockersInfo] = React.useState("");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (requestSantaState) {
      (async () => {
        await getSecretSantaState();
      })().then(() => {
        setRequestSantaState(false)
      });
    }
  }, [requestSantaState])

  useEffect(() => {
    if (pairsInfoApprove) {
      openPairsInfoDialog(false)
      setBackdropOpen(true)
      api.sender.sendSecretSantasPairs()
        .then(() => {
          setPairsInfoApprove(false)
          setRequestSantaState(true)
          setBackdropOpen(false)
        });
    }
  }, [pairsInfoApprove]);

  useEffect(() => {
    if (presentCollectionApprove) {
      openPresentCollectionDialog(false)
      setBackdropOpen(true)
      api.sender.sendSecretSantasPresents()
        .then(() => {
          setPresentCollectionApprove(false)
          setRequestSantaState(true)
          setBackdropOpen(false)
        });
    }
  }, [presentCollectionApprove]);

  const callSecretSantaChangeState = (state) => {
    api.secretSanta.changeState(state ? 'start' : 'finish')
      .then(
        () => {
          setRequestSantaState(true)
        }
      )
      .catch((error) => {
        props.onFetchFailed(error);
      })
  };

  const getSecretSantaState = () => {
    (async () => {
      await api.secretSanta.getState()
        .then(res => {
          setSantaState(res.intValue)
        })
        .catch((error) => {
          props.onFetchFailed(error);
        })
    })();
  };

  const makePairs = () => {
    api.secretSanta.makePairs()
      .then(() => {
        setRequestSantaState(true)
      });
  };

  const handleOnLockersSave = () => {
    api.secretSanta.addLockers(JSON.parse(lockersInfo))
      .then(() => {
        snackBarAddMessage(`Lockers info saved`, 'success')
      })
      .catch((error) => {
        props.onFetchFailed(error);
      })
  };

  const snackBarAddMessage = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SecretSantaPairsInfoSendApproveDialog
        component={'span'}
        open={pairsInfoDialog}
        handleClose={() => openPairsInfoDialog(false)}
        handleApprove={() => setPairsInfoApprove(true)}
      />
      <SecretSantaPresentCollectionApproveDialog
        component={'span'}
        open={presentCollectionDialog}
        handleClose={() => openPresentCollectionDialog(false)}
        handleApprove={() => setPresentCollectionApprove(true)}
      />
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Secret Santa
        </Typography>
        {/*{notificationSend ? <label color="red">Santa notifications have been sent!!</label> : false}*/}
        <br />
        <Button onClick={() => callSecretSantaChangeState(true)}
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={santaState !== 0}>Start Secret Santa</Button>
        <Button onClick={() => makePairs()}
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={santaState !== 1}>Make Secret Santa pairs</Button>

        <Button onClick={() => openPairsInfoDialog(true)}
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={santaState < 2}>Send pairs information</Button>
        <Button onClick={() => openPresentCollectionDialog(true)}
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={santaState < 2}>Send present collection information</Button>
        <Button onClick={() => callSecretSantaChangeState(false)}
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={santaState === 0}>Finish Secret Santa</Button>
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Lockers info
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          id='lockersInfo'
          name="lockersInfo"
          fullWidth
          multiline
          rows={10}
          value={lockersInfo}
          onChange={(event) => {setLockersInfo(event.target.value)}}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnLockersSave}
          className={classes.button}
        >
          Save
        </Button>

      </Paper>

    </div>
  );
}
