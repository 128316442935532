import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import DisplayMessage from "../util/DisplayMessage";
import RedeemIcon from '@mui/icons-material/Redeem';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { api } from "../../api/api";
import { useSnackbar } from "notistack";
import SecretSantaBecameConsentDialog from "../dialog/SecretSantaBecameConsentDialog";
import Box from "@mui/material/Box";
import SecretSantaPresentDeliveryConsentDialog from "../dialog/SecretSantaPresentDeliveryConsentDialog";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { preprocessLocations } from "../util/SecretSantaLocationUtil";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  hintTitle: {
    marginTop: theme.spacing(3),
  },

}));

export default function SecretSanta(props) {
  const classes = useStyles();
  const [checkIsSanta, setCheckIsSanta] = useState(true);
  const [isSanta, setIsSanta] = useState(false);
  const [santaRunning, setSantaRunning] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [wishes, setWishes] = useState('');
  const [santaState, setSantaState] = useState(undefined);
  //santa became consent dialog
  const [santaConsentDialog, openSantaConsentDialog] = useState(false);
  const [consentApprove, setConsentApproval] = useState(false);
  //santa present delivery consent dialog
  const [santaPresentDeliveryConsentDialog, openSantaPresentDeliveryConsentDialog] = useState(false);
  const [presentDeliveryConsentApprove, setPresentDeliveryConsentApproval] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([])

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (checkIsSanta) {
      (async () => {
        await api.secretSanta.getCurrentUserData()
          .then(santaState => {
            setSantaState(santaState)
            setSantaRunning(santaState.santaState !== 0)

            setWishes(santaState.santaWishes)
            setIsSanta(santaState.santa)
            santaState.location && setSelectedLocation(santaState.location.id)
            setCheckIsSanta(false)
            setErrorMessage(undefined)
          }).catch(() => {
            setErrorMessage("An error has occurred checking if you are Santa! :(")
            setCheckIsSanta(false)
          });
      })();
    }
  }, [checkIsSanta]);

  useEffect(() => {
    if (consentApprove) {
      becomeSanta()
    }
  }, [consentApprove]);

  useEffect(() => {
    if (presentDeliveryConsentApprove) {
      markDelivered()
    }
  }, [presentDeliveryConsentApprove]);

  useEffect(() => {
    props.setShowSearch(false);

    (async () => {
      await api.secretSanta.getLocations()
        .then(santaLocations => {
          setLocations(santaLocations)
        }).catch(() => {
          setErrorMessage("An error has occurred on secret santa locations load! :(")
          setCheckIsSanta(false)
        });
    })();
  }, []);


  const becomeSanta = () => {
    if (!isLocationSelected()) {
      return
    }
    api.secretSanta.become(selectedLocation)
      .then(() => {
        setCheckIsSanta(true);
        setErrorMessage(undefined);
        setConsentApproval(false);
        openSantaConsentDialog(false);
      })
      .catch(() => {
        setErrorMessage("An error has occurred, please try again!")
      });
  };

  const unbecomeSanta = () => {
    api.secretSanta.unbecome()
      .then(() => {
        setCheckIsSanta(true);
        setErrorMessage(undefined);
      })
      .catch(() => {
        setErrorMessage("An error has occurred, please try again!")
      });
  };

  const markDelivered = () => {
    api.secretSanta.markDeliveredByUser()
      .then(() => {
        setCheckIsSanta(true);
        setErrorMessage(undefined);
        setPresentDeliveryConsentApproval(false);
        openSantaPresentDeliveryConsentDialog(false);
      })
      .catch(() => {
        setErrorMessage("An error has occurred, please try again!")
      });
  };

  const handleOnSave = () => {
    api.secretSanta.wishes(wishes)
      .then(() => {
        setCheckIsSanta(true)
        snackBarAddMessage(`Your wishes were successfully saved`, 'success')
      })
      .catch((error) => {
        props.onFetchFailed(error);
      })
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedLocation(value);
    setErrorMessage(undefined);
  };

  const handleSelectBlur = () => {
    isLocationSelected();
  };

  const handleBecomeSanta = () => {
    if (isLocationSelected()) {
      openSantaConsentDialog(true)
    }
  }

  function isLocationSelected() {
    if (!selectedLocation) {
      setErrorMessage('It is required for Santa to have location assigned. Please select one');
      return false
    } else {
      return true
    }
  }


  const snackBarAddMessage = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const disableButtons = santaState?.santaState !== 1

  const presentDelivery = (deliveryStatus) => {
    switch (deliveryStatus) {
      case "NOT_DELIVERED":
      case "MARKED_AS_DELIVERED":
        return <Button
          variant="contained"
          color="success"
          onClick={() => openSantaPresentDeliveryConsentDialog(true)}
          disabled={santaState.deliveryStatus !== "NOT_DELIVERED"}
          startIcon={<RedeemIcon />}
        >
          I delivered the present!
        </Button>
      case "DELIVERY_CONFIRMED":
      case "DELIVERY_DOUBLE_CHECKED":
        return <Typography variant="h5" sx={{ color: 'green' }}><RedeemIcon /> Present delivery was confirmed by
          reindeer!</Typography>
      case "NOTIFICATION_SENT":
        return <Typography variant="h5" sx={{ color: 'blue' }}><RedeemIcon /> {santaState.receiver?.name} was notified
          about the
          present!</Typography>
    }
  };

  let linkToLockerGuide =
    <Link
      color="primary"
      href="https://sthermeshp.blob.core.windows.net/learn/Locker_guide.jpg"
      target="_blank"
      underline="hover">
      how to use locker
    </Link>;
  let linkToEPAMConnect = <Link
    color="primary"
    href="https://connect.epam.com/"
    target="_blank"
    underline="hover">
    EPAM Connect
  </Link>;
  return <>
    {!checkIsSanta
      && !santaRunning
      && <Typography variant="h4" gutterBottom>
        Secret Santa is not running at the moment, please wait for the next December!
      </Typography>
    }
    {santaRunning &&
      <Paper className={classes.paper}>
        <SecretSantaBecameConsentDialog
          component={'span'}
          open={santaConsentDialog}
          handleClose={() => openSantaConsentDialog(false)}
          handleApprove={() => setConsentApproval(true)}
        />
        <Typography variant="h4" gutterBottom>
          Secret Santa
        </Typography>

        {santaState.location
          &&
          <Typography variant="h7"
                      component="label"
                      htmlFor="select-location">
            You are registered as Secret Santa in {santaState.location.name}
          </Typography>}

        {santaState.receiver ? <Box>
            <SecretSantaPresentDeliveryConsentDialog
              component={'span'}
              open={santaPresentDeliveryConsentDialog}
              handleClose={() => openSantaPresentDeliveryConsentDialog(false)}
              handleApprove={() => setPresentDeliveryConsentApproval(true)}
            />
            <Typography variant="h5" color="primary">
              You are the Santa for <b>{santaState.receiver?.name}</b>!
            </Typography>
            <br />
            {santaState.deliveryStatus !== "NOTIFICATION_SENT"
              && <>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html:
                      `Don't forget to pack present, put receiver's name on it and place the present 
                  ${santaState.location.deliveryInstructions}
                  , the sooner the better!`
                  }}
                />
                <Typography>
                  <b>Here are some hints for the present from {santaState.receiver?.name}:</b>
                </Typography>
                <br />
                <Typography variant="h6" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
                  {santaState.receiverWishes}
                </Typography>
                <br />
              </>}
            {presentDelivery(santaState.deliveryStatus)}
            <br />
            {santaState.readyToBeCollect
              && <>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html:
                      `You can collect you present ${santaState.location.collectingInstructions}.`
                  }}
                />
              </>}
            <br />
            {(santaState.deliveringLocker)
              && <>
                Instruction on&nbsp;{linkToLockerGuide}
                <p>You need to:</p>
                <ul>
                  <li>Deliver a gift to location mentioned above</li>
                  <li>Find a locker and open the cell (choose "Smart Locker" in the&nbsp;{linkToEPAMConnect} application)</li>
                  <li>Place a gift there</li>
                  <li>Close the cell
                    <br />
                    <b>*Please, pay attention that the size of the cell is limited. (320х400х320)</b>
                  </li>
                </ul>

              </>}
            {(santaState.receivingLocker)
              && <>
                Reminder on &nbsp;{linkToLockerGuide}
                <p>You need to:</p>
                <ul>
                  <li>Find the locker in location mentioned above</li>
                  <li>Open the cell (choose "Smart Locker" in the&nbsp;{linkToEPAMConnect} application)</li>
                  <li>Take a gift from there</li>
                  <li>Close the cell</li>
                </ul>

              </>}
          </Box>
          : <div className={classes.paper}>
            {errorMessage ? <DisplayMessage message={errorMessage} /> : false}

            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>

              {!isSanta ?
                <Box
                  sx={{
                    padding: 2,
                  }}
                >
                  <FormControl
                    variant="standard"
                    fullWidth
                  >
                    <InputLabel id="demo-multiple-checkbox-label">Choose location where you want to
                      participate</InputLabel>
                    <Select
                      labelId="select-location-input"
                      value={selectedLocation || ""}
                      onChange={handleSelectChange}
                      onBlur={handleSelectBlur}
                      fullWidth
                    >
                      {preprocessLocations(locations)
                        .map((location) => (
                          <MenuItem key={location.id} value={location.id} disabled={location.isCountry}>
                            {location.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleBecomeSanta()}
                    disabled={disableButtons}
                    className={classes.button}
                    endIcon={<RedeemIcon />}
                  >
                    Become Santa!
                  </Button>
                </Box>
                : <>
                  <Box
                    sx={{
                      padding: 2,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={unbecomeSanta}
                      disabled={disableButtons}
                      endIcon={<SentimentDissatisfiedIcon />}
                    >
                      Unbecome Santa
                    </Button>

                    <Typography
                      // variant="body1"
                      gutterBottom
                      className={classes.hintTitle}
                    >
                      Write a letter to your Santa with some ideas on what you would like to receive as a present (Not
                      guaranteed but will be a hint):
                    </Typography>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      id='wishes'
                      name="wishes"
                      label="I wish..."
                      fullWidth
                      multiline
                      disabled={disableButtons}
                      rows={4}
                      value={wishes}
                      onChange={(event) => {
                        setWishes(event.target.value)
                      }}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOnSave}
                    className={classes.button}
                    disabled={disableButtons || wishes === santaState.santaWishes}
                  >
                    Save
                  </Button>
                </>}
            </div>
          </div>
        }
        <br />
        <Link
          color="primary"
          href="https://kb.epam.com/display/GDOKB/EPAM+Secret+Santa"
          target="_blank"
          underline="hover">
          For more information welcome to our KB!
        </Link>
      </Paper>}

  </>;
}
