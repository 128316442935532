import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import {DialogContentText} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function SecretSantaPresentDeliveryConsentDialog(props) {

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            scroll={'paper'}
        >
            <DialogTitle>{"Secret Santa present delivery confirmation"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="h6">
                        Please confirm that:
                    </Typography>
                    <Typography variant="h6">
                        * You packed the present and put a tag with receiver name on it
                    </Typography>
                    <Typography variant="h6">
                        * You brought the present in the office according received instructions
                    </Typography>

                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Close
                </Button>
                <Button
                    onClick={props.handleApprove}
                    variant="contained"
                    color="success"
                    autoFocus
                >
                    I confirm, Santa's job is over!
                </Button>
            </DialogActions>
        </Dialog>
    );
}
