import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { api } from "../api/api";

export default function SentEmailsChart() {

  const [dates, setDates] = useState([]);
  const [regular, setRegular] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [softLanding, setSoftLanding] = useState([]);
  const [aegon, setAegon] = useState([]);
  const [courierMatch, setCourierMatch] = useState([]);
  const [anniversaryCount, setAnniversaryCount] = useState([]);
  const [telegramRegistration, setTelegramRegistration] = useState([]);
  const [secretSantaRegistration, setSecretSantaRegistration] = useState([]);
  const [secretSantaParticipants, setSecretSantaParticipants] = useState([]);
  const [secretSantaPresent, setSecretSantaPresent] = useState([]);

  useEffect(() => {
    api.history.sentLettersChart()
      .then(data => {
        setDates(data.dates.map(d => d + ' 12:00'))
        setRegular(data.regularPackagesCount)
        setReminders(data.remindersCount)
        setSoftLanding(data.softLandingPackagesCount)
        setAegon(data.aegonPackagesCount)
        setCourierMatch(data.courierMatchCount)
        setAnniversaryCount(data.anniversaryCount)
        setTelegramRegistration(data.telegramRegistrationCount)
        setSecretSantaRegistration(data.secretSantaRegistrationPackagesCount)
        setSecretSantaParticipants(data.secretSantaParticipantsPackagesCount)
        setSecretSantaPresent(data.secretSantaPresentPackagesCount)
      })
  }, []);

  return (
    <>
      <Chart
        options={{
          chart: {
            id: "basic-bar",
            type: 'line',
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          theme: {
            palette: 'palette8'
          },
          xaxis: {
            type: 'datetime',
            categories: dates
          },
          legend: {
            position: 'right',
            offsetY: 30
          },
        }}
        series={[
          {
            name: "Regular packages",
            data: regular
          },
          {
            name: "Reminders",
            data: reminders
          },
          {
            name: "Courier Match",
            data: courierMatch
          },
          {
            name: "Anniversary notifications",
            data: anniversaryCount
          },
          {
            name: "Soft landing packages",
            data: softLanding
          },
          {
            name: "AEGON packages",
            data: aegon
          },
          {
            name: "Telegram registrations",
            data: telegramRegistration
          },
          {
            name: "Secret Santa registration",
            data: secretSantaRegistration
          },
          {
            name: "Secret Santa participant",
            data: secretSantaParticipants
          },
          {
            name: "Secret Santa presents",
            data: secretSantaPresent
          }
        ]}
        type="line"
        height={500}
      />
    </>
  );
}
