export const preprocessLocations = (locations) => {
  const result = [];
  let currentCountry = null;

  const sortedLocations = [...locations].sort((a, b) => a.name.localeCompare(b.name));

  sortedLocations.forEach((location) => {
    const [countryName] = location.name.split(" > ");

    if (currentCountry !== countryName) {
      // Add country header if it's different from the last one
      result.push({ id: `country-${countryName}`, name: countryName, isCountry: true });
      currentCountry = countryName;
    }

    // Add the actual location
    result.push({ id: location.id, name: location.name, isCountry: false });
  });

  return result;
};
